/* Global styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  background: linear-gradient(135deg, #1a202c 0%, #2d3748 100%);
  color: #E5E7EB;
  min-height: 100vh;
}

/* Container layout */
.app-container {
  display: flex;
  min-height: 100vh;
}

/* Sidebar styles */
.sidebar {
  width: 250px;
  background-color: #1a202c;
  padding: 2rem;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  overflow-y: auto;
}

.sidebar nav ul {
  list-style-type: none;
  padding: 0;
}

.sidebar nav ul li {
  margin-bottom: 1.5rem;
}

.sidebar nav ul li a {
  color: #E5E7EB;
  text-decoration: none;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
}

.sidebar nav ul li a:hover {
  color: #4299E1;
}

.sidebar nav ul li a svg {
  margin-right: 10px;
}

/* Main content */
.main-content {
  flex: 1;
  margin-left: 250px;
  padding: 2rem;
}

/* Wallet List Section */
.wallet-list-section {
  background-color: #2D3748;
  max-width: 600px;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.section-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #E5E7EB;
}

.wallet-list {
  max-height: 600px;
  max-width: 600px;
  overflow-y: auto;
  border-radius: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.wallet-row {
  display: flex;
  justify-content: space-between;
  max-width: 600px;
  padding: 1rem;
  background-color: #4A5568;
  margin-bottom: 0.75rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.wallet-row:hover {
  background-color: #718096;
  transform: translateY(-2px);
}

.wallet-address {
  font-size: 0.9rem;
  color: #E5E7EB;
  word-break: break-all;
}

.wallet-balance {
  font-size: 0.9rem;
  color: #A0AEC0;
  text-align: right;
  min-width: 100px;
}

/* Button styling */
button {
  background-color: #4299E1;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1rem;
  display: block;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

button:hover {
  background-color: #3182CE;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

button:active {
  background-color: #2B6CB0;
  transform: scale(0.98);
}

button:disabled {
  background-color: #A0AEC0;
  cursor: not-allowed;
  opacity: 0.6;
}

/* Input fields */
input, .input-field {
  background-color: #4A5568;
  color: #E5E7EB;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid #718096;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
}

input:focus, .input-field:focus {
  border-color: #4299E1;
  box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5);
  outline: none;
}

/* Wallet overview styles */
.wallet-overview {
  background-color: #2D3748;
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.overview-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.stat-item {
  background-color: #4A5568;
  padding: 1.5rem;
  border-radius: 0.5rem;
  text-align: center;
}

.stat-item h3 {
  font-size: 1rem;
  color: #A0AEC0;
  margin-bottom: 0.5rem;
}

.stat-item p {
  font-size: 1.5rem;
  font-weight: bold;
  color: #E5E7EB;
}

.wallet-balances h3 {
  margin-bottom: 1rem;
}

.wallet-balances ul {
  list-style-type: none;
  padding: 0;
}

.wallet-balances li {
  background-color: #4A5568;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
}

/* Settings Modal */
.settings-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.settings-modal.active {
  opacity: 1;
  visibility: visible;
}

.settings-modal-content {
  background-color: #2D3748;
  padding: 2rem;
  border-radius: 1rem;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #E5E7EB;
  position: relative;
}

/* Login and Register */
.login-container, .register-container {
  max-width: 400px;
  margin: 3rem auto;
  padding: 2rem;
  background-color: #2D3748;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.login-container h2, .register-container h2 {
  text-align: center;
  color: #E5E7EB;
  margin-bottom: 1.5rem;
}

.login-container form, .register-container form {
  display: flex;
  flex-direction: column;
}

.login-container input, .register-container input {
  margin-bottom: 1rem;
}

.login-container button, .register-container button {
  margin-top: 1rem;
}

.error-message {
  color: #FC8181;
  text-align: center;
  margin-bottom: 1rem;
}

/* Dashboard Layout */
.dashboard {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dashboard-actions {
  display: flex;
  gap: 10px;
}

/* Navigation Tabs */
.dashboard-nav {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.dashboard-nav button {
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dashboard-nav button.active {
  background-color: #007bff;
  color: white;
}

.dashboard-nav button:hover {
  background-color: #e0e0e0;
}

/* Time Range Selector */
.time-range-selector {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.time-range-selector button {
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.time-range-selector button.active {
  background-color: #28a745;
  color: white;
}

.time-range-selector button:hover {
  background-color: #e0e0e0;
}

/* Dashboard Content */
.dashboard-content {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.dashboard-overview,
.dashboard-details {
  display: grid;
  gap: 20px;
}

/* Wallet List */
.wallet-list {
  background-color: #f8f9fa;
  border-radius: 5px;
  padding: 20px;
}

/* Transactions List */
.transactions-list {
  background-color: #f8f9fa;
  border-radius: 5px;
  padding: 20px;
  overflow-x: auto;
}

.transactions-list table {
  width: 100%;
  border-collapse: collapse;
}

.transactions-list th,
.transactions-list td {
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #dee2e6;
}

.transactions-list th {
  background-color: #e9ecef;
  font-weight: bold;
}

/* Loading Overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .dashboard-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .dashboard-actions {
    margin-top: 10px;
  }

  .dashboard-nav,
  .time-range-selector {
    flex-wrap: wrap;
  }

  .dashboard-content {
    grid-template-columns: 1fr;
  }
}

/* Button Styles */
button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
}

button:hover {
  transform: translateY(-2px);
}

button:active {
  transform: translateY(1px);
}

.refresh-button {
  background-color: #17a2b8;
  color: white;
}

.logout-button {
  background-color: #dc3545;
  color: white;
}

/* Icons */
button svg {
  margin-right: 5px;
}

.rotate {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

/* Chart Styles */
.chart-visualization {
  background-color: #f8f9fa;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
}

/* Toast Notifications */
.Toastify__toast-container {
  z-index: 9999;
}

.Toastify__toast {
  border-radius: 4px;
  padding: 16px;
}

.Toastify__toast--success {
  background-color: #28a745;
}

.Toastify__toast--error {
  background-color: #dc3545;
}

/* Token Sniper */
.token-sniper {
  background-color: #2D3748;
  padding: 2rem;
  border-radius: 1rem;
  margin-top: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.token-sniper input,
.token-sniper select {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #718096;
  background-color: #4A5568;
  color: #E5E7EB;
}

/* Pump Volume */
.pump-volume-section {
  background-color: #2D3748;
  padding: 2rem;
  border-radius: 1rem;
  margin-top: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pump-volume-section h2 {
  color: #E5E7EB;
  margin-bottom: 1.5rem;
}

.pump-volume-section form {
  display: flex;
  flex-direction: column;
}

.pump-volume-section input[type="checkbox"] {
  width: auto;
  margin-right: 0.5rem;
}

.pump-volume-section label {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.pump-volume-section .message {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #4A5568;
  color: #E5E7EB;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #2D3748;
}

::-webkit-scrollbar-thumb {
  background: #4A5568;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #718096;
}

/* Dashboard animations */
.dashboard-content {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Loading animations */
.loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Improved visual hierarchy */
h1, h2, h3 {
  font-weight: bold;
}

h1 { font-size: 2.5rem; }
h2 { font-size: 2rem; }
h3 { font-size: 1.5rem; }

/* Hover effects for sidebar items and buttons */
.sidebar nav ul li a:hover,
button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Progress bars */
.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #4A5568;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar-fill {
  height: 100%;
  background-color: #4299E1;
  transition: width 0.5s ease-in-out;
}

/* Chart styles */
.chart-visualization {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  margin-top: 2rem;
}

.chart {
  background-color: #2D3748;
  padding: 1rem;
  border-radius: 0.5rem;
}

.generate-wallets-form {
  display: flex;
  margin-bottom: 1rem;
}

.generate-wallets-form input {
  flex: 1;
  margin-right: 1rem;
}

.generate-wallets-form button {
  flex: 0 0 auto;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.refresh-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 0;
  transition: transform 0.3s ease;
  width: auto;
  margin-top: 0;
}

.refresh-button:hover {
  transform: rotate(100deg);
}

.refresh-button:disabled {
  cursor: not-allowed;
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.rotate {
  animation: rotate 1s linear infinite;
}

.token-icon {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
  vertical-align: middle;
}

.pump-volume-section .wallet-list-container {
  margin-top: 2rem;
  border-top: 1px solid #4A5568;
  padding-top: 1rem;
}

.pump-volume-section .wallet-list-container h3 {
  margin-bottom: 1rem;
}
.search-refresh-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.search-container {
  position: relative;
  flex-grow: 1;
  margin-right: 1rem;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #718096;
}

.search-input {
  width: 100%;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  border: 1px solid #4A5568;
  border-radius: 0.25rem;
  background-color: #2D3748;
  color: #E5E7EB;
}

.refresh-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #4299E1;
  font-size: 1.2rem;
}

.refresh-button:disabled {
  color: #718096;
  cursor: not-allowed;
}

.rotate {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  100% {
      transform: rotate(360deg);
  }
}

.load-more-button {
  width: 100%;
  padding: 0.5rem;
  margin-top: 1rem;
  background-color: #4299E1;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.load-more-button:hover {
  background-color: #3182CE;
}
