/* Full-screen gradient background */
body {
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background: linear-gradient(135deg, #2c3e50 0%, #34495e 100%);
  color: #ecf0f1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1.title {
  text-align: center;
  color: #2ecc71;
  font-size: 3rem;
  margin-bottom: 30px;
}

.container {
  max-width: 1200px;
  width: 100%;
  padding: 20px;
  background: rgba(44, 62, 80, 0.85);
  border-radius: 12px;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.4);
}

/* Flex container to hold wallet list and form section side by side */
.flex-container {
  display: flex;
  justify-content: space-between;
}

/* Wallet list styling */
.wallet-list {
  width: 48%;
  background-color: #1c2833;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.4);
}

.wallet-list h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #2ecc71;
}

.table-auto {
  width: 100%;
}

.table-auto th,
.table-auto td {
  padding: 10px;
  color: white;
}

/* Form and distributor section */
.form-section {
  width: 48%;
  background-color: #2c3e50;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.4);
}

/* Footer styling */
footer {
  text-align: center;
  color: #2ecc71;
  margin-top: 20px;
}

button {
  background-color: #1abc9c;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
}

button:hover {
  background-color: #16a085;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.6);
}

button:active {
  transform: scale(0.98); /* Slight shrink on click */
}
